/* Ensure the body and html take the full height */
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  
  /* Styles for OwlCarousel */
  .owl-carousel.home-slider {
    height: 100vh;
    width: 100%;
  }
  
  .owl-carousel.home-slider .slider-item {
    position: relative;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .owl-carousel.home-slider .slider-item .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #232429;
    opacity: 0.2;
    width: 100%;
    z-index: 0;
  }
  
  .owl-carousel.home-slider .slider-item .container {
    position: relative;
    z-index: 1;
  }
  
  .slider-text {
    display: flex;
    color: #fff; /* Ensure text is readable on the background */
    width: 100%;
    z-index: 1;
  }
  
  .text {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
  }
  
  .btn-white {
    background-color: #fff;
    border-color: #fff;
    color: #000;
  }
  
  .btn {
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .text h1 {
      font-size: 28px;
    }
  
    .text p {
      font-size: 16px;
    }
  
    .btn {
      padding: 8px 16px;
      font-size: 14px;
    }
  }


  body {
    overflow-x: hidden;
}

  
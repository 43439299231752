/* Loader container styles */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: white;
}

/* Background overlay styles */
.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: -1; /* Places it behind the loader */
}

/* Loader styles */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
}

.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  border-radius: 50%;
  background: rgb(171,107,241);
  -webkit-mask: repeating-linear-gradient(#000 0 10px, #0000 0 20px);
}

.loader:after {
  -webkit-mask: repeating-linear-gradient(#0000 0 10px, #000 0 20px);
  animation: l36 1.5s infinite;
}

@keyframes l36 {
  0%,
  10%  {transform: rotate(0) translate(0)}
  35%  {transform: rotate(0) translate(100%)}
  66%  {transform: rotate(180deg) translate(100%)}
  90%,
  100% {transform: rotate(180deg) translate(0)}
}

/* Ensure #root covers the entire viewport */
#root {
  width: 100vw;
  height: 100vh;
}

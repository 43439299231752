/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
 :root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --primary: #AB6BF1;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

 figcaption, figure, footer, header, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

output {
  display: inline-block; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.img-fluid {
  max-width: 55%;
  height: auto; }

.figure {
  display: inline-block; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.container,
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

 .col-12, .col, .col-sm-12, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-12, .col-md, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-6, .col-lg-8, .col-lg-10, .col-lg {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }


input[type="time"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  -o-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group {
    border-top: 0; }

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.media-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    -ms-transform: translate(0, -50px);
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      -o-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    -o-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        -o-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.rounded {
  border-radius: 0.25rem !important; }

.d-block {
  display: block !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

@media (min-width: 992px) {
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; } }

.justify-content-start {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.align-items-start {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  -ms-grid-row-align: stretch !important;
  align-self: stretch !important; }

@media (min-width: 768px) {
  .justify-content-md-end {
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; } }

.position-static {
  position: static !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.w-100 {
  width: 100% !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.p-0 {
  padding: 0 !important; }


.px-0 {
  padding-right: 0 !important; }


.px-0 {
  padding-left: 0 !important; }


.py-2 {
  padding-top: 0.5rem !important; }


.px-2 {
  padding-right: 0.5rem !important; }


.py-2 {
  padding-bottom: 0.5rem !important; }


.px-2 {
  padding-left: 0.5rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }


.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }


.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.ml-auto {
  margin-left: auto !important; }

@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0 !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; } }

@media (min-width: 992px) {
  .p-lg-5 {
    padding: 3rem !important; }
  
  .px-lg-5 {
    padding-right: 3rem !important; }
  
  .px-lg-5 {
    padding-left: 3rem !important; } }

.text-center {
  text-align: center !important; }

.visible {
  visibility: visible !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  pre {
    white-space: pre-wrap !important; }
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; } }

body {
  font-family: "Roboto", Arial, sans-serif;
  background: #fff;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
  color: #999999; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  color: #AB6BF1; }
  a:hover, a:focus {
    text-decoration: none;
    color: #AB6BF1;
    outline: none !important; }

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  font-family: "Roboto Condensed", Arial, sans-serif; }

.container {
  max-width: 1050px; }

.container-2 {
  margin: 0 0 0 auto !important;
  max-width: 1245px; }

.wrap {
  padding: 7px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2; }
  @media (max-width: 991.98px) {
    .wrap {
      display: none; } }
  .wrap p {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.8); }
  .wrap .phone {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.8); }
    .wrap .phone a {
      color: white;
      font-size: 12px; }
    .wrap .phone .fa {
      color: rgba(255, 255, 255, 0.8); }
    .wrap .phone .mailus {
      color: rgba(255, 255, 255, 0.6); }

.social-media {
  display: inline-block; }
  .social-media p a {
    width: 28px;
    height: 28px;
    margin-right: 7px;
    border-radius: 50%; }
    .social-media p a span {
      color: #fff; }
    .social-media p a:hover span {
      color: #fff; }

.ftco-navbar-light {
  background: rgba(255, 255, 255, 0.1) !important;
  position: absolute;
  top: 44px;
  left: 0;
  right: 0;
  z-index: 3;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0; }
  @media (max-width: 991.98px) {
    .ftco-navbar-light {
      background: #000000 !important;
      position: relative;
      top: 0;
      padding-top: 0;
      padding-bottom: 0; } }
  .ftco-navbar-light .container {
    width: 1110px;
    margin: 0 auto;
    padding: 0; }
  .ftco-navbar-light .navbar-brand {
    color: #fff;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 15px; }
    @media (max-width: 991.98px) {
      .ftco-navbar-light .navbar-brand {
        color: #fff;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 0;
        background: transparent; } }
  .ftco-navbar-light .navbar-nav {
    margin-right: 1em; }
    @media (max-width: 991.98px) {
      .ftco-navbar-light .navbar-nav {
        padding-bottom: 20px;
        margin-right: 0; } }
    .ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
      font-size: 15px;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: 20px;
      padding-right: 20px;
      color: #fff;
      font-weight: 500;
      opacity: 1 !important; }
      .ftco-navbar-light .navbar-nav > .nav-item > .nav-link:hover, .ftco-navbar-light .navbar-nav > .nav-item > .nav-link:focus {
        color: #fff; }
      @media (max-width: 991.98px) {
        .ftco-navbar-light .navbar-nav > .nav-item > .nav-link {
          padding-left: 0;
          padding-right: 0;
          padding-bottom: 0;
          padding-top: 0.3rem !important;
          padding-bottom: 0.3rem !important;
          border: none;
          color: #fff; } }
    .ftco-navbar-light .navbar-nav > .nav-item .dropdown-menu {
      border: none;
      background: #fff;
      -webkit-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
      -moz-box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41);
      box-shadow: 0px 10px 34px -20px rgba(0, 0, 0, 0.41); }
    .ftco-navbar-light .navbar-nav > .nav-item.active > a {
      color: #AB6BF1; }
    @media (max-width: 991.98px) {
      .ftco-navbar-light .navbar-nav > .nav-item.active > a {
        color: #AB6BF1; } }
  .ftco-navbar-light .navbar-toggler {
    border: none;
    color: rgba(255, 255, 255, 0.5) !important;
    cursor: pointer;
    padding-right: 0;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: .1em; }
    .ftco-navbar-light .navbar-toggler:hover, .ftco-navbar-light .navbar-toggler:focus {
      text-decoration: none;
      color: #AB6BF1;
      outline: none !important; }
  .ftco-navbar-light.scrolled {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    margin-top: -130px;
    background: #fff !important;
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 0; }
    @media (max-width: 991.98px) {
      .ftco-navbar-light.scrolled .nav-item > .nav-link {
        padding-left: 20px !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important; } }
    .ftco-navbar-light.scrolled .nav-item.active > a {
      color: #AB6BF1 !important; }
    @media (max-width: 991.98px) {
      .ftco-navbar-light.scrolled .navbar-nav {
        background: none;
        border-radius: 0px;
        margin-left: 0 !important; } }
    .ftco-navbar-light.scrolled .navbar-toggler {
      border: none;
      color: rgba(0, 0, 0, 0.5) !important;
      border-color: rgba(0, 0, 0, 0.5) !important;
      cursor: pointer;
      margin-right: 15px !important;
      text-transform: uppercase;
      font-size: 16px;
      letter-spacing: .1em; }
    .ftco-navbar-light.scrolled .nav-link {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
      color: #000000 !important; }
      .ftco-navbar-light.scrolled .nav-link.active {
        color: #AB6BF1 !important; }
    .ftco-navbar-light.scrolled.awake {
      margin-top: 0px;
      -webkit-transition: .3s all ease-out;
      -o-transition: .3s all ease-out;
      transition: .3s all ease-out; }
    .ftco-navbar-light.scrolled.sleep {
      -webkit-transition: .3s all ease-out;
      -o-transition: .3s all ease-out;
      transition: .3s all ease-out; }
    .ftco-navbar-light.scrolled .navbar-brand {
      color: #000000;
      padding-top: 1rem;
      padding-bottom: 1rem; }
      @media (max-width: 991.98px) {
        .ftco-navbar-light.scrolled .navbar-brand {
          color: #000000;
          padding-left: 20px; } }

.navbar-brand {
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 0; }

.hero-wrap {
  width: 100%;
  height: 100%;
  position: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed; }
  .hero-wrap .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .5; }
  .hero-wrap.hero-wrap-2 {
    height: 600px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
    .hero-wrap.hero-wrap-2 .overlay {
      width: 100%;
      opacity: .5;
      background: #232429; }
    .hero-wrap.hero-wrap-2 .slider-text {
      height: 600px; }

.slider-text {
  position: relative; }
  .slider-text .subheading {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 700;
    text-transform: uppercase; }
  .slider-text h1 {
    font-size: 50px;
    color: #fff;
    line-height: 1.2;
    font-weight: 700; }
    @media (max-width: 991.98px) {
      .slider-text h1 {
        font-size: 40px; } }
    @media (max-width: 767.98px) {
      .slider-text h1 {
        font-size: 30px; } }
  .slider-text p {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.9); }
    .slider-text p strong {
      font-weight: 700; }
      .slider-text p strong a {
        color: #000000; }
  .slider-text .breadcrumbs {
    font-size: 18px;
    margin-bottom: 20px;
    z-index: 99;
    font-weight: 400; }
    .slider-text .breadcrumbs span {
      color: rgba(255, 255, 255, 0.8); }
      .slider-text .breadcrumbs span i {
        color: rgba(255, 255, 255, 0.8); }
      .slider-text .breadcrumbs span a {
        color: white; }
        .slider-text .breadcrumbs span a:hover, .slider-text .breadcrumbs span a:focus {
          color: #AB6BF1; }
          .slider-text .breadcrumbs span a:hover i, .slider-text .breadcrumbs span a:focus i {
            color: #AB6BF1; }
  .slider-text .bread {
    font-weight: 700;
    text-transform: uppercase; }

.owl-carousel {
  position: relative; }
  .owl-carousel .owl-item {
    opacity: 1; }
    .owl-carousel .owl-item.active {
      opacity: 1; }
  .owl-carousel .owl-dots {
    text-align: center; }
    .owl-carousel .owl-dots .owl-dot {
      width: 10px;
      height: 10px;
      margin: 5px;
      border-radius: 50%;
      background: #e6e6e6;
      position: relative; }
      .owl-carousel .owl-dots .owl-dot:after {
        position: absolute;
        top: -2px;
        left: -2px;
        right: 0;
        bottom: 0;
        width: 14px;
        height: 14px;
        content: '';
        border: 1px solid #4d4d4d;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
      .owl-carousel .owl-dots .owl-dot:hover, .owl-carousel .owl-dots .owl-dot:focus {
        outline: none !important; }
      .owl-carousel .owl-dots .owl-dot.active {
        background: #b3b3b3; }
        .owl-carousel .owl-dots .owl-dot.active:after {
          border-color: #fff; }
  .owl-carousel.home-slider {
    position: relative;
    height: 700px;
    z-index: 0; }
    .owl-carousel.home-slider .slider-item {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 700px;
      position: relative;
      z-index: 0;
      overflow: hidden; }
      .owl-carousel.home-slider .slider-item .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: #232429;
        opacity: .2;
        width: 100%;
        z-index: -1; }
      @media (max-width: 1199.98px) {
        .owl-carousel.home-slider .slider-item {
          background-position: center center !important; } }
      .owl-carousel.home-slider .slider-item .slider-text {
        height: 700px;
        position: relative; }
        .owl-carousel.home-slider .slider-item .slider-text .text {
          z-index: 0;
          color: rgba(255, 255, 255, 0.9);
          position: relative; }
        .owl-carousel.home-slider .slider-item .slider-text h1 {
          color: #fff;
          font-size: 52px;
          font-weight: 700;
          margin-bottom: 20px;
          text-transform: uppercase;
          letter-spacing: 1px; }
          .owl-carousel.home-slider .slider-item .slider-text h1 span {
            color: #AB6BF1; }
        .owl-carousel.home-slider .slider-item .slider-text .subheading {
          font-size: 13px;
          color: white;
          font-weight: 700;
          letter-spacing: 5px;
          display: inline-block;
          margin-bottom: 10px;
          position: relative;
          z-index: 0; }
        .owl-carousel.home-slider .slider-item .slider-text p {
          font-weight: 400;
          font-size: 16px; }
        .owl-carousel.home-slider .slider-item .slider-text .btn {
          padding: 12px 18px !important;
          font-weight: 700; }
    .owl-carousel.home-slider .owl-nav {
      position: absolute;
      top: 50%;
      width: 100%; }
      .owl-carousel.home-slider .owl-nav .owl-prev,
      .owl-carousel.home-slider .owl-nav .owl-next {
        position: absolute;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        outline: none !important;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;
        opacity: 0; }
        .owl-carousel.home-slider .owl-nav .owl-prev span:before,
        .owl-carousel.home-slider .owl-nav .owl-next span:before {
          font-size: 30px;
          color: rgba(255, 255, 255, 0.5);
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          -webkit-transition: all 0.3s ease;
          -ms-transition: all 0.3s ease;
          transition: all 0.3s ease; }
        .owl-carousel.home-slider .owl-nav .owl-prev:hover span:before, .owl-carousel.home-slider .owl-nav .owl-prev:focus span:before,
        .owl-carousel.home-slider .owl-nav .owl-next:hover span:before,
        .owl-carousel.home-slider .owl-nav .owl-next:focus span:before {
          color: #fff; }
      .owl-carousel.home-slider .owl-nav .owl-prev {
        left: 0;
        margin-left: 0; }
      .owl-carousel.home-slider .owl-nav .owl-next {
        right: 0;
        margin-right: 0; }
    .owl-carousel.home-slider:hover .owl-nav .owl-prev,
    .owl-carousel.home-slider:hover .owl-nav .owl-next {
      opacity: 1; }
    .owl-carousel.home-slider:hover .owl-nav .owl-prev {
      margin-left: 50px; }
    .owl-carousel.home-slider:hover .owl-nav .owl-next {
      margin-right: 50px; }
    .owl-carousel.home-slider .owl-dots {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 120px;
      width: 100%;
      text-align: center; }
      @media (max-width: 767.98px) {
        .owl-carousel.home-slider .owl-dots {
          bottom: 5px; } }
      .owl-carousel.home-slider .owl-dots .owl-dot {
        width: 10px;
        height: 10px;
        margin: 5px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.4); }
        .owl-carousel.home-slider .owl-dots .owl-dot:after {
          border-color: rgba(255, 255, 255, 0.4); }
        .owl-carousel.home-slider .owl-dots .owl-dot.active {
          background: #fff; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.bg-light {
  background: #f4f5f9 !important; }

.btn {
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 24px 36px -11px rgba(0, 0, 0, 0.09);
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 500; }
  .btn:hover, .btn:active, .btn:focus {
    outline: none; }
  .btn.btn-primary {
    background: #AB6BF1 !important;
    border: 1px solid #AB6BF1 !important;
    color: #fff !important; }
    .btn.btn-primary:hover {
      border: 1px solid #AB6BF1;
      background: transparent;
      color: #AB6BF1; }
  .btn.btn-white {
    background: #fff !important;
    border: 1px solid #fff !important;
    color: #000000 !important; }
    .btn.btn-white:hover {
      border: 1px solid #fff;
      background: transparent;
      color: #fff; }

.btn-custom {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
  border: 2px solid #AB6BF1;
  padding: 3px 10px; }

.ftco-portfolio {
  position: relative; }
  .ftco-portfolio .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 40%;
    content: '';
    background-color: black;
  }

.project-wrap {
  position: relative;
  height: 340px;
  overflow: hidden;
  border-radius: 4px;
  z-index: 0;
  margin-bottom: 30px; }
  .project-wrap:after, .project-wrap:before {
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    background: #232429;
    width: 100%;
    height: 50%;
    content: '';
    opacity: .9;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    -webkit-transition: all 0.6s ease;
    -ms-transition: all 0.6s ease;
    transition: all 0.6s ease; }
  .project-wrap:after {
    bottom: -50%; }
  .project-wrap:before {
    top: -50%; }
  .project-wrap .text {
    padding: 20px;
    opacity: 0;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .project-wrap .text h3 {
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 2px; }
      .project-wrap .text h3 a {
        color: #fff; }
    .project-wrap .text span {
      color: #AB6BF1;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase; }
    .project-wrap .text .icon {
      position: absolute;
      bottom: 32px;
      right: 20px;
      width: 40px;
      height: 40px;
      background: #AB6BF1;
      border-radius: 50%;
      z-index: 1; }
      .project-wrap .text .icon span {
        color: #fff; }
  .project-wrap:hover .text {
    opacity: 1; }
  .project-wrap:hover:after {
    bottom: 0;
    left: 0; }
  .project-wrap:hover:before {
    top: 0;
    right: 0; }

.portfolio-single .heading-title {
  font-weight: 700;
  text-transform: uppercase; }

.portfolio-category {
  font-size: 13px;
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #AB6BF1;
  text-transform: uppercase; }

.ftco-services {
  background: url(./images/ause_your_illusion.png.webp) repeat;
  background-attachment: fixed; }

.services {
  width: 100%;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  background: #1a1a1a;
  position: relative;
  -webkit-box-shadow: 0px 2px 25px -16px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 2px 25px -16px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 2px 25px -16px rgba(0, 0, 0, 0.26); }
  .services .icon {
    line-height: 1.3;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    margin-bottom: 7px; }
    .services .icon span {
      font-size: 50px;
      color: #AB6BF1; }
  .services .media-body {
    position: relative; }
    .services .media-body h3 {
      font-size: 18px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase; }
  .services .btn-custom {
    position: absolute;
    bottom: -20px;
    right: 50px;
    width: 40px;
    height: 40px;
    background: #232429;
    border-radius: 50%;
    opacity: 0;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .services .btn-custom span {
      color: #AB6BF1; }
    @media (max-width: 991.98px) {
      .services .btn-custom {
        right: 30px;
        opacity: 1; } }
  .services.active {
    background: #AB6BF1; }
    .services.active h3 {
      color: #000000; }
    .services.active .icon span {
      color: #000000; }
  .services:hover {
    background: #AB6BF1; }
    .services:hover h3 {
      color: #000000; }
    .services:hover .icon span {
      color: #000000; }
    .services:hover .btn-custom {
      opacity: 1;
      right: 30px; }

.progress-wrap {
  width: 100%;
  margin-bottom: 30px; }
  .progress-wrap h3 {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 700;
    text-transform: uppercase; }

.progress {
  height: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #e6e6e6;
  overflow: visible; }

.progress-bar {
  background: #AB6BF1;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 12px;
  line-height: 1.2;
  color: #000000;
  font-weight: 600;
  position: relative;
  overflow: visible;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px; }
  .progress-bar:after {
    position: absolute;
    top: -2px;
    right: 0;
    width: 34px;
    height: 34px;
    content: '';
    background: #AB6BF1;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;
    opacity: 0; }
  .progress-bar span {
    position: absolute;
    top: -30px;
    right: 0;
    font-size: 16px;
    font-weight: 700;
    color: #AB6BF1; }

.ftco-faqs .heading-section2 {
  font-weight: 600;
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 1px; }

.ftco-faqs .img {
  height: 350px; }

.ftco-faqs .myaccordion {
  margin: 0 auto;
  overflow: hidden; }

.ftco-faqs .myaccordion .card,
.ftco-faqs .myaccordion .card:last-child .card-header {
  border: none;
  background: transparent; }

.ftco-faqs .myaccordion .card-header {
  border: none;
  background: transparent; }
  .ftco-faqs .myaccordion .card-header p {
    position: relative;
    font-weight: 400;
    font-size: 18px;
    text-align: left;
    text-transform: uppercase; }

.ftco-faqs .myaccordion .fa {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.3); }

.ftco-faqs .myaccordion .btn {
  width: 100%;
  font-weight: 600;
  color: black;
  padding: 15px 20px !important;
  text-transform: capitalize;
  letter-spacing: 0;
  border-radius: 0 !important;
  margin-bottom: 10px;
  border-radius: 4px !important;
  -webkit-box-shadow: 0px 2px 8px -4px rgba(0, 0, 0, 0.21) !important;
  -moz-box-shadow: 0px 2px 8px -4px rgba(0, 0, 0, 0.21) !important;
  box-shadow: 0px 2px 8px -4px rgba(0, 0, 0, 0.21) !important; }

.ftco-faqs .myaccordion .btn-link:hover,
.ftco-faqs .myaccordion .btn-link:focus {
  text-decoration: none; }

.ftco-faqs [data-toggle="collapse"] .fa:before {
  content: "\f078";
  font-family: "FontAwesome";
  font-style: normal; }

.ftco-faqs [data-toggle="collapse"].collapsed .fa:before {
  content: "\f077";
  font-family: "FontAwesome";
  font-style: normal; }

.ftco-faqs button[aria-expanded="true"] {
  background-color: #AB6BF1;
  color: #fff !important; }
  .ftco-faqs button[aria-expanded="true"] .fa {
    color: #fff !important; }

.ftco-faqs button[aria-expanded="false"] {
  background-color: #fff; }

.ftco-faqs .card-body ol li {
  margin-bottom: 10px;
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.8); }
  .ftco-faqs .card-body ol li:last-child {
    margin-bottom: 0; }

.block-7 {
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 0;
  background: #fff;
  -webkit-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.05);
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  @media (max-width: 991.98px) {
    .block-7 {
      margin-top: 30px; } }
  .block-7 .img {
    height: 800px;
    width: 100%; }
  .block-7 .price {
    margin: 0;
    padding: 0;
    display: block;
    width: 100%; }
    .block-7 .price sup {
      font-size: 24px;
      top: -0.8em;
      color: #000000; }
    .block-7 .price .number {
      font-size: 40px;
      font-weight: 700;
      color: #000000; }
    .block-7 .price sub {
      color: #000000;
      font-size: 16px; }
  .block-7 .pricing-text, .block-7 .pricing-text li {
    padding: 0;
    margin: 0; }
  .block-7 .pricing-text li {
    list-style: none;
    margin-bottom: 15px;
    color: #000000; }
    .block-7 .pricing-text li span.fa {
      color: #AB6BF1; }
  .block-7 .btn-primary {
    color: #fff;
    text-transform: uppercase;
    font-style: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    width: 100%;
    display: block; }
    .block-7 .btn-primary:hover, .block-7 .btn-primary:focus {
      background: #AB6BF1 !important;
      color: #fff; }
  .block-7:hover, .block-7:focus {
    -webkit-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.11);
    -moz-box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 24px 48px -13px rgba(0, 0, 0, 0.11); }

.form-control {
  height: 45px;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  border-radius: 2px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1); }
  .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(0, 0, 0, 0.3) !important; }
  .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.3) !important; }
  .form-control:-ms-input-placeholder {
    /* IE 0+ */
    color: rgba(0, 0, 0, 0.3) !important; }
  .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 0, 0.3) !important; }
  .form-control:focus, .form-control:active {
    border-color: #AB6BF1 !important; }

textarea.form-control {
  height: inherit !important; }

.ftc-animate {
  opacity: 0;
  visibility: hidden; }

.bg-darken {
  background: #232429 !important; }

.ftco-about {
  position: relative;
  z-index: 0; }
  .ftco-about .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    opacity: .1;
    background: #000000;
    z-index: -1; }
  .ftco-about .img {
    -webkit-box-shadow: 0px 2px 25px -13px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0px 2px 25px -13px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 2px 25px -13px rgba(0, 0, 0, 0.26);
    width: 100%; }
    @media (max-width: 767.98px) {
      .ftco-about .img {
        height: 400px; } }
  .ftco-about .about-intro {
    width: 100%;
    position: relative;
    z-index: 99; }
  .ftco-about .year-stablish {
    background: #AB6BF1;
    padding: 15px;
    width: 100%;
    margin-top: 40px; }
    @media (min-width: 768px) {
      .ftco-about .year-stablish {
        margin-left: -110px;
        width: 70%; } }
    .ftco-about .year-stablish .icon2 {
      width: 60px;
      height: 60px;
      margin: 0 auto;
      background: #232429;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%; }
      .ftco-about .year-stablish .icon2 span {
        color: #fff;
        line-height: 1.2;
        font-size: 30px; }
    .ftco-about .year-stablish .text {
      width: calc(100% - 60px); }
    @media (max-width: 767.98px) {
      .ftco-about .year-stablish {
        left: 0; } }
  .ftco-about .text strong.number {
    font-weight: 700;
    font-size: 32px;
    color: #fff;
    line-height: 1;
    margin-bottom: 5px; }
  .ftco-about .text span {
    display: block;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 700; }

.staff {
  width: 100%;
  margin-bottom: 30px;
  border-radius: 0px;
  overflow: hidden;
  position: relative;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease; }
  .staff .img-wrap {
    height: 350px;
    position: relative;
    z-index: 0;
    overflow: hidden; }
    .staff .img-wrap:after, .staff .img-wrap:before {
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
      background: #AB6BF1;
      width: 100%;
      height: 50%;
      content: '';
      opacity: 0;
      -moz-transition: all 0.6s ease;
      -o-transition: all 0.6s ease;
      -webkit-transition: all 0.6s ease;
      -ms-transition: all 0.6s ease;
      transition: all 0.6s ease; }
    .staff .img-wrap:after {
      bottom: -50%; }
    .staff .img-wrap:before {
      top: -50%; }
  .staff .img {
    width: 100%;
    display: block;
    background-position: top center;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .staff .text {
    position: relative;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: rgba(255, 255, 255, 0.9);
    z-index: 2;
    opacity: 0; }
    .staff .text h3 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      margin-bottom: 0px;
      color: #fff;
      text-transform: uppercase; }
    .staff .text .position {
      color: #fff;
      display: block;
      font-weight: 500;
      display: inline-block;
      margin-bottom: 20px;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 2px; }
    .staff .text .faded {
      opacity: 1; }
  .staff .ftco-social {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .staff .ftco-social li {
      width: 30px;
      height: 30px;
      position: relative; }
      .staff .ftco-social li a {
        width: 30px;
        height: 30px;
        background: transparent;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.5); }
        .staff .ftco-social li a span {
          font-size: 14px;
          color: #fff; }
  @media (max-width: 991.98px) {
    .staff .text {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 1;
      background: #232429; } }
  .staff:hover .text, .staff:focus .text {
    opacity: 1; }
  .staff:hover .img-wrap:before, .staff:hover .img-wrap:after, .staff:focus .img-wrap:before, .staff:focus .img-wrap:after {
    opacity: .9; }
  .staff:hover .img-wrap:after, .staff:focus .img-wrap:after {
    bottom: 0;
    left: 0; }
  .staff:hover .img-wrap:before, .staff:focus .img-wrap:before {
    top: 0;
    right: 0; }

.ftco-social {
  padding: 0; }
  .ftco-social li {
    list-style: none;
    margin-right: 10px;
    display: inline-block; }

.about-author .desc h3 {
  font-size: 20px;
  font-weight: 600; }

.ftco-section {
  padding: 7em 0;
  position: relative;
  width: 100%; }
  @media (max-width: 767.98px) {
    .ftco-section {
      padding: 6em 0; } }

.ftco-no-pt {
  padding-top: 0 !important; }

.ftco-no-pb {
  padding-bottom: 0 !important; }

.ftco-intro {
  color: rgba(255, 255, 255, 0.8);
  z-index: 0;
  overflow: hidden;
  position: relative;
  z-index: 99;
  margin-bottom: -80px; }
  .ftco-intro .img {
    padding: 3em;
    position: relative;
    z-index: 0;
    overflow: hidden;
    border-radius: 5px; }
    @media (max-width: 767.98px) {
      .ftco-intro .img {
        padding: 2em 1em; } }
    .ftco-intro .img .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      opacity: .8;
      background: #AB6BF1;
      z-index: -1; }
  @media (max-width: 991.98px) {
    .ftco-intro {
      background-position: center center !important; } }
  .ftco-intro h2 {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase; }
    @media (max-width: 767.98px) {
      .ftco-intro h2 {
        font-size: 20px; } }
    .ftco-intro h2 span {
      background: #AB6BF1; }

.subscribe-form {
  width: 100%; }
  .subscribe-form .form-group {
    position: relative;
    margin-bottom: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0; }
    .subscribe-form .form-group input {
      background: white !important;
      border: 1px solid transparent;
      color: rgba(0, 0, 0, 0.4) !important;
      font-size: 16px;
      height: 50px;
      border-radius: 5px 0px 0px 5px; }
      .subscribe-form .form-group input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgba(0, 0, 0, 0.4) !important; }
      .subscribe-form .form-group input::-moz-placeholder {
        /* Firefox 19+ */
        color: rgba(0, 0, 0, 0.4) !important; }
      .subscribe-form .form-group input:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba(0, 0, 0, 0.4) !important; }
      .subscribe-form .form-group input:-moz-placeholder {
        /* Firefox 18- */
        color: rgba(0, 0, 0, 0.4) !important; }
    .subscribe-form .form-group .submit {
      color: #fff !important;
      background: #9d8909 !important;
      border-radius: 0px 5px 5px 0px;
      font-size: 16px; }
      .subscribe-form .form-group .submit:hover {
        cursor: pointer; }
  .subscribe-form .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: rgba(255, 255, 255, 0.8); }

.ftco-footer {
  font-size: 16px;
  padding: 12em 0 0 0;
  background: #141b25;
  background: #232429;
  color: rgba(255, 255, 255, 0.9);
  position: relative;
  z-index: 0; }
  .ftco-footer .logo a {
    position: relative; }
  .ftco-footer .ftco-footer-widget h2 {
    font-weight: normal;
    color: #fff;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase; }
  .ftco-footer .ftco-footer-widget ul li {
    margin-bottom: 10px; }
    .ftco-footer .ftco-footer-widget ul li a {
      color: rgba(255, 255, 255, 0.7); }
      .ftco-footer .ftco-footer-widget ul li a span {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.7); }
      .ftco-footer .ftco-footer-widget ul li a:hover {
        color: #AB6BF1; }
        .ftco-footer .ftco-footer-widget ul li a:hover span {
          color: #AB6BF1; }
  .ftco-footer .ftco-footer-widget .btn-primary {
    background: #fff !important;
    border: 2px solid #fff !important; }
    .ftco-footer .ftco-footer-widget .btn-primary:hover {
      background: #fff;
      border: 2px solid #fff !important; }
  .ftco-footer p {
    color: rgba(255, 255, 255, 0.9); }
  .ftco-footer a {
    color: #fff; }
    .ftco-footer a:hover {
      color: #AB6BF1; }
  .ftco-footer .ftco-heading-2 {
    font-size: 17px;
    font-weight: 400;
    color: #000000; }
  .ftco-footer .bg-black {
    background: #1e1f23; }

.ftco-footer-social li {
  list-style: none;
  margin: 0 10px 0 0;
  display: inline-block; }
  .ftco-footer-social li a {
    height: 40px;
    width: 40px;
    display: block;
    float: left;
    color: #fff;
    border-radius: 50%;
    position: relative;
    background: #AB6BF1; }
    .ftco-footer-social li a span {
      position: absolute;
      font-size: 18px;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      color: #fff !important; }
    .ftco-footer-social li a:hover {
      color: #AB6BF1; }

#map {
  width: 100%;
  height: 400px; }
  @media (max-width: 991.98px) {
    #map {
      height: 300px; } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(205, 179, 12, 0.4);
    -webkit-box-shadow: 0 0 0 0 rgba(205, 179, 12, 0.4);
    box-shadow: 0 0 0 0 rgba(205, 179, 12, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 30px rgba(205, 179, 12, 0);
    -webkit-box-shadow: 0 0 0 30px rgba(205, 179, 12, 0);
    box-shadow: 0 0 0 30px rgba(205, 179, 12, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(205, 179, 12, 0);
    -webkit-box-shadow: 0 0 0 0 rgba(205, 179, 12, 0);
    box-shadow: 0 0 0 0 rgba(205, 179, 12, 0); } }

.heading-section .subheading {
  font-size: 22px;
  display: block;
  margin-bottom: 15px;
  font-weight: 600;
  color: #AB6BF1;
  text-transform: uppercase; }

.heading-section h2 {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 3px; }
  .heading-section h2 span {
    font-weight: 400; }
  @media (max-width: 767.98px) {
    .heading-section h2 {
      font-size: 28px; } }

.heading-section.heading-section-white .subheading {
  color: #AB6BF1; }

.heading-section.heading-section-white h2 {
  color: #fff; }

.img,
.blog-img,
.user-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

.testimony-section {
  position: relative; }
  .testimony-section .owl-carousel {
    margin: 0; }
  .testimony-section .owl-carousel .owl-stage-outer {
    padding: 1em 0 1em 0;
    position: relative; }
  .testimony-section .owl-nav {
    position: absolute;
    top: 100%;
    width: 100%; }
    .testimony-section .owl-nav .owl-prev,
    .testimony-section .owl-nav .owl-next {
      position: absolute;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-top: -10px;
      outline: none !important;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      opacity: 0; }
      .testimony-section .owl-nav .owl-prev span:before,
      .testimony-section .owl-nav .owl-next span:before {
        font-size: 30px;
        color: rgba(0, 0, 0, 0.5);
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease; }
      .testimony-section .owl-nav .owl-prev:hover span:before, .testimony-section .owl-nav .owl-prev:focus span:before,
      .testimony-section .owl-nav .owl-next:hover span:before,
      .testimony-section .owl-nav .owl-next:focus span:before {
        color: #fff; }
    .testimony-section .owl-nav .owl-prev {
      left: 50%;
      margin-left: -80px; }
    .testimony-section .owl-nav .owl-next {
      right: 50%;
      margin-right: -80px; }
  .testimony-section:hover .owl-nav .owl-prev,
  .testimony-section:hover .owl-nav .owl-next {
    opacity: 1; }
  .testimony-section:hover .owl-nav .owl-prev {
    left: 50%;
    margin-left: -80px; }
  .testimony-section:hover .owl-nav .owl-next {
    right: 50%;
    margin-right: -80px; }
  .testimony-section .owl-dots .owl-dot {
    width: 10px;
    height: 10px;
    margin: 5px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1); }
    .testimony-section .owl-dots .owl-dot:after {
      border-color: rgba(0, 0, 0, 0.1); }
    .testimony-section .owl-dots .owl-dot.active, .testimony-section .owl-dots .owl-dot:focus {
      background: #AB6BF1;
      outline: none; }
      .testimony-section .owl-dots .owl-dot.active:after, .testimony-section .owl-dots .owl-dot:focus:after {
        border-color: #AB6BF1; }

.testimony-wrap {
  display: block;
  position: relative;
  background: white;
  padding: 0 20px;
  border: 1px solid rgba(0, 0, 0, 0.02);
  -webkit-box-shadow: 0px 5px 21px -14px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: 0px 5px 21px -14px rgba(0, 0, 0, 0.14);
  box-shadow: 0px 5px 21px -14px rgba(0, 0, 0, 0.14);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px; }
  .testimony-wrap .user-img {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%; }
  .testimony-wrap .name {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0;
    color: #232429; }
  .testimony-wrap .position {
    font-size: 12px;
    color: #cccccc;
    color: #AB6BF1;
    text-transform: uppercase;
    font-weight: 500; }
  .testimony-wrap .star {
    color: #AB6BF1;
    margin-bottom: 0; }
    .testimony-wrap .star span {
      font-size: 12px; }

.star {
  color: #AB6BF1; }

.image-popup {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1; }

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0; }

#section-counter {
  position: relative;
  z-index: 0;
  background-attachment: fixed; }
  #section-counter .overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    z-index: -1;
    background: #232429; 
    background-image: url(./images/mcodez_bg.webp);
  }

@media (max-width: 1199.98px) {
  .ftco-counter {
    background-position: center center !important; } }

.ftco-counter .img {
  display: block;
  width: 100%; }
  @media (max-width: 767.98px) {
    .ftco-counter .img {
      height: 400px;
      background-position: top center !important; } }

.ftco-counter .text strong.number {
  font-weight: 700;
  font-size: 32px;
  color: #fff; }

.ftco-counter .text span {
  display: block;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600; }

.ftco-counter .icon {
  width: 70px;
  height: 70px;
  margin: 0 auto;
  background: #AB6BF1;
  border-radius: 3px; }
  .ftco-counter .icon span {
    color: #fff;
    font-size: 48px; }

@media (max-width: 767.98px) {
  .ftco-counter .counter-wrap {
    margin-bottom: 20px; } }

.block-20 {
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 250px;
  width: 100%;
  position: relative;
  display: block; }

.blog-entry {
  width: 100%;
  margin-bottom: 40px; }
  @media (max-width: 767.98px) {
    .blog-entry {
      margin-bottom: 30px; } }
  .blog-entry .text {
    position: relative;
    border-top: 0;
    border-radius: 2px;
    width: 100%;
    padding: 40px 20px 20px 20px;
    margin: 0 0 0 auto;
    -webkit-box-shadow: 0px 4px 24px -16px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 4px 24px -16px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 4px 24px -16px rgba(0, 0, 0, 0.15);
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease; }
    .blog-entry .text .tag {
      color: #b3b3b3; }
    .blog-entry .text .heading {
      font-size: 20px;
      margin-bottom: 16px;
      font-weight: 700;
      text-transform: uppercase; }
      .blog-entry .text .heading a {
        color: #000000; }
        .blog-entry .text .heading a:hover, .blog-entry .text .heading a:focus, .blog-entry .text .heading a:active {
          color: #AB6BF1; }
    .blog-entry .text .btn-custom {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 1px; }
  .blog-entry .meta {
    position: absolute;
    top: -31px;
    left: 30px;
    right: 30px;
    background: #fff;
    padding: 10px 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 10px 22px -16px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 10px 22px -16px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 10px 22px -16px rgba(0, 0, 0, 0.15); }
    .blog-entry .meta p {
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 5px;
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500; }
      .blog-entry .meta p a {
        color: #232429;
        font-size: 12px; }
        .blog-entry .meta p a .fa {
          color: #AB6BF1; }
        .blog-entry .meta p a:hover {
          color: #AB6BF1; }
  .blog-entry:hover .text {
    border-radius: 0 0 5px 5px;
    background: #fff;
    -webkit-box-shadow: 0px 4px 24px -16px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 4px 24px -16px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 4px 24px -16px rgba(0, 0, 0, 0.2); }

.block-23 ul {
  padding: 0; }
  .block-23 ul li, .block-23 ul li > a {
    display: table;
    line-height: 1.5;
    margin-bottom: 15px; }
  .block-23 ul li span {
    color: rgba(255, 255, 255, 0.7);
    color: #AB6BF1; }
  .block-23 ul li .icon, .block-23 ul li .text {
    display: table-cell;
    vertical-align: top;
    color: rgba(255, 255, 255, 0.8) !important; }
  .block-23 ul li .icon {
    width: 40px;
    font-size: 18px;
    padding-top: 2px;
    color: #AB6BF1 !important; }

.block-27 ul {
  padding: 0;
  margin: 0; }
  .block-27 ul li {
    display: inline-block;
    margin-bottom: 4px;
    font-weight: 400; }
    .block-27 ul li a, .block-27 ul li span {
      color: #000000;
      text-align: center;
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      border: 1px solid #e6e6e6; }
    .block-27 ul li.active a, .block-27 ul li.active span, .block-27 ul li:hover a, .block-27 ul li:hover span {
      background: #232429;
      color: #fff;
      border: 1px solid transparent; }

.wrapper {
  width: 100%;
  -webkit-box-shadow: 0px 0px 37px -16px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 37px -16px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 37px -16px rgba(0, 0, 0, 0.15); }

.contact-wrap {
  background: #fff; }
  .contact-wrap h3 {
    color: #000000;
    text-transform: uppercase;
    font-weight: 700; }

.info-wrap h3 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700; }

.info-wrap .dbox {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px; }
  .info-wrap .dbox:last-child {
    margin-bottom: 0; }
  .info-wrap .dbox p {
    margin-bottom: 0; }
    .info-wrap .dbox p span {
      font-weight: 700;
      color: #AB6BF1; }
    .info-wrap .dbox p a {
      color: #fff; }
  .info-wrap .dbox .icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1); }
    .info-wrap .dbox .icon span {
      font-size: 20px;
      color: #fff; }
  .info-wrap .dbox .text {
    width: calc(100% - 50px); }

@media (min-width: 992px) {
  .info-wrap {
    border-radius: 0 5px 5px 0; } }

.contactForm .form-control {
  border: 2px solid #AB6BF1 !important;
  padding: 15px;
  color: rgba(0, 0, 0, 0.8) !important; }
  .contactForm .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(0, 0, 0, 0.8) !important; }
  .contactForm .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.8) !important; }
  .contactForm .form-control:-ms-input-placeholder {
    /* IE 0+ */
    color: rgba(0, 0, 0, 0.8) !important; }
  .contactForm .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(0, 0, 0, 0.8) !important; }

.block-21 .blog-img {
  display: block;
  height: 80px;
  width: 80px; }

.block-21 .text {
  width: calc(100% - 80px); }
  .block-21 .text .heading {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0; }
    .block-21 .text .heading a {
      color: #000000; }
      .block-21 .text .heading a:hover, .block-21 .text .heading a:active, .block-21 .text .heading a:focus {
        color: #AB6BF1; }
  .block-21 .text .meta > div {
    display: inline-block;
    font-size: 11px;
    margin-right: 5px; }
    .block-21 .text .meta > div a {
      color: gray;
      color: #AB6BF1; }

/* Blog*/

.tagcloud a {
  text-transform: uppercase;
  display: inline-block;
  padding: 4px 10px;
  margin-bottom: 7px;
  margin-right: 4px;
  border-radius: 4px;
  color: #000000;
  border: 1px solid #ccc;
  font-size: 15px;
  font-weight: 700; }

.tagcloud .active {
  text-transform: uppercase;
  display: inline-block;
  padding: 4px 10px;
  margin-bottom: 7px;
  margin-right: 4px;
  border-radius: 4px;
  color: white;
  border: 1px solid #ccc;
  background-color: black;
  font-size: 15px;
  font-weight: 700; }

.comment-form-wrap {
  clear: both; }
  .comment-form-wrap .comment-form {
    background: #fff;
    -webkit-box-shadow: 0px 2px 25px -16px rgba(0, 0, 0, 0.26);
    -moz-box-shadow: 0px 2px 25px -16px rgba(0, 0, 0, 0.26);
    box-shadow: 0px 2px 25px -16px rgba(0, 0, 0, 0.26); }
  .comment-form-wrap .form-group label {
    font-size: 12px;
    text-transform: uppercase;
    color: #AB6BF1;
    font-weight: 600; }
  .comment-form-wrap .form-control {
    height: 45px;
    background: transparent;
    color: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    border-radius: 2px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    .comment-form-wrap .form-control::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(0, 0, 0, 0.3) !important; }
    .comment-form-wrap .form-control::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(0, 0, 0, 0.3) !important; }
    .comment-form-wrap .form-control:-ms-input-placeholder {
      /* IE 0+ */
      color: rgba(0, 0, 0, 0.3) !important; }
    .comment-form-wrap .form-control:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(0, 0, 0, 0.3) !important; }
    .comment-form-wrap .form-control:focus, .comment-form-wrap .form-control:active {
      border-color: #AB6BF1 !important; }
  .comment-form-wrap textarea.form-control {
    height: inherit !important; }

.comment-list {
  padding: 0;
  margin: 0; }
  .comment-list .children {
    padding: 50px 0 0 40px;
    margin: 0;
    float: left;
    width: 100%; }
  .comment-list li {
    padding: 0;
    margin: 0 0 30px 0;
    float: left;
    width: 100%;
    clear: both;
    list-style: none; }
    .comment-list li .vcard {
      width: 80px;
      float: left; }
      .comment-list li .vcard img {
        width: 50px;
        border-radius: 50%; }
    .comment-list li .comment-body {
      float: right;
      width: calc(100% - 80px); }
      .comment-list li .comment-body h3 {
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase; }
      .comment-list li .comment-body .meta {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: .1em;
        color: #ccc;
        font-weight: 500;
        color: #AB6BF1;
        display: block;
        margin-bottom: 10px; }
      .comment-list li .comment-body .reply {
        padding: 5px 10px;
        background: #e6e6e6;
        color: #fff;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: .1em;
        font-weight: 500;
        border-radius: 4px;
        background: #AB6BF1; }
        .comment-list li .comment-body .reply:hover {
          color: #fff;
          background: black; }

.search-form {
  border-radius: 5px; }
  .search-form .form-group {
    position: relative; }
    .search-form .form-group input {
      padding-right: 50px;
      font-size: 14px;
      border: none; }
  .search-form .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }

.sidebar-box {
  margin-bottom: 30px;
  padding: 10px;
  font-size: 15px;
  width: 100%; }
  .sidebar-box *:last-child {
    margin-bottom: 0; }
  .sidebar-box h3 {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 700;
    text-transform: uppercase; }

.categories li {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dee2e6;
  list-style: none; }
  .categories li:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0; }
  .categories li a {
    display: block;
    color: #1a1a1a; }
    .categories li a span {
      position: absolute;
      right: 0;
      top: 0;
      color: #ccc; }
  .categories li.active a {
    color: #000000;
    font-style: italic; }

#ftco-loader {
  position: fixed;
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .2s ease-out, visibility 0s linear .2s;
  -o-transition: opacity .2s ease-out, visibility 0s linear .2s;
  transition: opacity .2s ease-out, visibility 0s linear .2s;
  z-index: 1000; }

#ftco-loader.fullscreen {
  padding: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  background-color: #fff;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

#ftco-loader.show {
  -webkit-transition: opacity .4s ease-out, visibility 0s linear 0s;
  -o-transition: opacity .4s ease-out, visibility 0s linear 0s;
  transition: opacity .4s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1; }

#ftco-loader .circular {
  -webkit-animation: loader-rotate 2s linear infinite;
  animation: loader-rotate 2s linear infinite;
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  display: block;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

#ftco-loader .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: loader-dash 1.5s ease-in-out infinite;
  animation: loader-dash 1.5s ease-in-out infinite;
  stroke-linecap: round; }

@keyframes loader-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -136px; } }


/* For webkit browsers like Chrome, Safari */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: grey; /* Color of the track */
}

::-webkit-scrollbar-thumb {
  background-color: white; /* Color of the thumb */
  border-radius: 10px;    /* Roundness of the thumb */
  border: 2px solid black; /* Space around thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color when hovering over the thumb */
}


.flaticon-ux {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url('./fonts/designing.svg');
  background-size: contain;
  background-repeat: no-repeat;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%);
}


.flaticon-stats {
display: inline-block;
width: 50px;
height: 50px;
background-image: url('./fonts/world-wide.svg');
background-size: contain;
background-repeat: no-repeat;
filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%);
}


.flaticon-web-programming {
display: inline-block;
width: 50px;
height: 50px;
background-image: url('./fonts/coding.svg');
background-size: contain;
background-repeat: no-repeat;
filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%);
}

.flaticon-light-bulb{
display: inline-block;
width: 50px;
height: 50px;
background-image: url('./fonts/project.svg');
background-size: contain;
background-repeat: no-repeat;
filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%);
}


.flaticon-branding{
display: inline-block;
width: 50px;
height: 50px;
background-image: url('./fonts/mobile.svg');
background-size: contain;
background-repeat: no-repeat;
filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%);
}


.flaticon-bullhorn{
display: inline-block;
width: 50px;
height: 50px;
background-image: url('./fonts/smm.svg');
background-size: contain;
background-repeat: no-repeat;
filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%);
}


.flaticon-vector{
display: inline-block;
width: 50px;
height: 50px;
background-image: url('./fonts/custom.svg');
background-size: contain;
background-repeat: no-repeat;
filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%);
}


.flaticon-file{
display: inline-block;
width: 50px;
height: 50px;
background-image: url('./fonts/check-list-icon.svg');
background-size: contain;
background-repeat: no-repeat;
filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%);
}


.flaticon-waiter{
display: inline-block;
width: 50px;
height: 50px;
background-image: url('./fonts/user.svg');
background-size: contain;
background-repeat: no-repeat;
filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%);
}

.flaticon-good-review{
display: inline-block;
width: 50px;
height: 50px;
background-image: url('./fonts/client.svg');
background-size: contain;
background-repeat: no-repeat;
filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(360deg) brightness(100%) contrast(100%);
}

.flaticon-php{
display: inline-block;
width:150px;
height: 150px;
background-image: url('./fonts/php.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-figma{
display: inline-block;
width:150px;
height: 150px;
background-image: url('./fonts/figma.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-adobe{
display: inline-block;
width:150px;
height: 150px;
background-image: url('./fonts/adobe.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-adobe-photoshop{
display: inline-block;
width:150px;
height: 150px;
background-image: url('./fonts/adobe-photoshop.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-html{
display: inline-block;
width:150px;
height: 150px;
background-image: url('./fonts/html.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-css{
display: inline-block;
width:150px;
height: 150px;
background-image: url('./fonts/css.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-flutter{
display: inline-block;
width: 150px;
height: 150px;
background-image: url('./fonts/flutter.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-dart{
display: inline-block;
width: 150px;
height: 150px;
background-image: url('./fonts/dart.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-firebase{
display: inline-block;
width: 150px;
height: 150px;
background-image: url('./fonts/firebase.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-mysql{
display: inline-block;
width: 150px;
height: 150px;
background-image: url('./fonts/mysql.svg');
background-size: contain;
background-repeat: no-repeat;
}


.flaticon-node{
display: inline-block;
width: 150px;
height: 150px;
background-image: url('./fonts/node.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-react{
display: inline-block;
width: 150px;
height: 150px;
background-image: url('./fonts/react.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-mongodb{
display: inline-block;
width: 150px;
height: 150px;
background-image: url('./fonts/mongodb.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-express{
display: inline-block;
width: 150px;
height: 150px;
background-image: url('./fonts/express.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-laravel{
display: inline-block;
width: 150px;
height: 150px;
background-image: url('./fonts/laravel.svg');
background-size: contain;
background-repeat: no-repeat;
}

.flaticon-jquery{
display: inline-block;
width: 150px;
height: 150px;
background-image: url('./fonts/jquery.svg');
background-size: contain;
background-repeat: no-repeat;
}


.flaticon-javascript{
display: inline-block;
width: 150px;
height: 150px;
background-image: url('./fonts/javascript.svg');
background-size: contain;
background-repeat: no-repeat;
}


.flaticon-bootstrap{
display: inline-block;
width: 150px;
height: 150px;
background-image: url('./fonts/bootstrap.svg');
background-size: contain;
background-repeat: no-repeat;
}

.services{
height: 200px;
}

.ftco-services {
background: url(./images/ause_your_illusion.png.webp) repeat;
background-attachment: fixed;
}


.icon-list {
list-style-type: none;
padding: 0;
}
.icon-list li {
margin: 10px 0;
display: flex;
align-items: center;
}
.icon-list li i {
margin-right: 10px;
}

.tag-cloud-link{
font-size: 28px;
}


.block-7 .imgs {
height: 800px;
width: 100%; 
background-size: cover; /* Ensure the image covers the entire div */
background-position: center; /* Center the image */
background-repeat: no-repeat; /* Prevent the image from repeating */
}

.imgs {
background-size: cover; /* Ensure the image covers the entire div */
background-position: center; /* Center the image */
background-repeat: no-repeat; /* Prevent the image from repeating */
width: 100%;
height: 220px; /* Initial height for large screens */
border-radius: 30px;
}

@media (max-width: 1200px) {
.imgs {
  height: 600px !important; /* Adjust height for tablets and small desktops */
}
}

@media (max-width: 768px) {
.imgs {
  height: 400px !important; /* Adjust height for large smartphones and tablets */
}
}

@media (max-width: 480px) {
.imgs {
  height: 300px !important; /* Adjust height for small smartphones */
}
}

.btn:hover{
background-color: white;
}


.slider-text .breadcrumbs {
font-size: 17px !important;
}

.fa-check-circle{
color:var(--indigo)
}




.road-map-main {
margin: 50px 0 51px;

.road-map-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 175px;

  @media (max-width: 991px) {
    margin-bottom: 25px;
    height: auto;
    display: block;
  }

  &::before,
  &::after {
    content: "";
    width: 100%;
    clear: both;
    display: block;
  }

  .road-map-circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 25px solid transparent;
    border-top-color: var(--dark);
    border-right-color: var(--dark);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: rotate(45deg);

    @media (max-width: 992px) {
      position: unset;
      border: 25px solid var(--dark);
    }

    .road-map-circle-text {
      width: 110px;
      height: 110px;
      border-radius: 50%;
      background-color: var(--primary);
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      text-transform: capitalize;
      color: #fff;
      box-shadow: 0px 0px 10px 5px #00000021;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      transform: rotate(-45deg);
    }
  }

  .road-map-card {
    width: 35%;
    background: var(--dark);
    padding: 20px 20px;
    z-index: 1;
    position: absolute;
    right: 0;
    border-radius: 5px;

    &::before {
      content: "";
      width: 25%;
      height: 20px;
      background: var(--dark);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -23%;
      z-index: -1;
    }

    @media (max-width: 991px) {
      width: 100%;
      margin-top: 30px;
      position: unset;

      &::before {
        width: 20px;
        height: 30%;
        top: 50%;
        transform: translateX(-50%);
        left: 50%;
      }
    }

    @media (max-width: 425px) {
      top: 45%;
    }

    .card-head {
      font-size: 20px;
      font-weight: 600;
      text-transform: capitalize;
      margin: 0 0 15px;
      color: #fff;
    }

    .card-text {
      color: #fff;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media (max-width: 1199px) {
        -webkit-line-clamp: 4;
      }
    }
  }

  &:nth-of-type(even) {
    .road-map-card {
      right: auto;
      left: 0;

      &::before {
        left: auto;
        right: -23%;
      }

      @media (max-width: 991px) {
        &::before {
          left: 50%;
          right: auto;
        }
      }
    }

    .road-map-circle {
      border-bottom-color: var(--dark);
      border-left-color: var(--dark);
      border-top-color: transparent;
      border-right-color: transparent;

      @media (max-width: 991px) {
        border-color: #7a7bd7;
      }
    }
  }
}
}
